import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[twodecimalOnly]'
})
export class TwodecimalDirective {
  private regex: RegExp = /^\d*\.?\d{0,2}$/; // Regex to allow numbers with up to 2 decimal places
  private specialKeys: string[] = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete','Control']; // Allow navigation keys
  
  constructor(private el: ElementRef) {}
  
  // Listen to keydown events to allow typing
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this.specialKeys.includes(event.key) || (event.ctrlKey && (event.key === 'v' || event.key === 'c' || event.key ==='z'))) {
      return; // Allow special keys
    }
  
    // const current: string = this.el.nativeElement.value;
    // const next: string = current.concat(event.key);
  
    const inputElement = this.el.nativeElement;
    const currentValue: string = inputElement.value;
    const selectionStart = inputElement.selectionStart || 0;
    const selectionEnd = inputElement.selectionEnd || 0;

    // Determine the value if the key were to be added
    const next = currentValue.substring(0, selectionStart) + event.key + currentValue.substring(selectionEnd);

    // Prevent invalid input
    if (!String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
  
  // Listen to input events to sanitize the value
  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = this.el.nativeElement.value;
    if (!String(input).match(this.regex)) {
      this.el.nativeElement.value = input.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); // Remove invalid characters
    }
  }
  
  // Listen to paste events to validate pasted content
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData?.getData('text') || '';
    if (!String(clipboardData).match(this.regex)) {
      event.preventDefault();
    }
  }

  // // Allow decimal numbers and negative values
  // private regex: RegExp = new RegExp(/^-?\d*\.?\d{0,2}$/g);
  // // Allow key codes for special events. Reflect :
  // // Backspace, tab, end, home
  // private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  // constructor(private el: ElementRef) {
  // }
  // @HostListener('keydown', ['$event'])
  // onKeyDown(event: KeyboardEvent) {
    
  //   // Allow Backspace, tab, end, and home keys
  //   if (this.specialKeys.indexOf(event.key) !== -1) {
  //     return;
  //   }
  //   let current: string = this.el.nativeElement.value;
  //   const position = this.el.nativeElement.selectionStart;
  //   const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
  //   if (next && !String(next).match(this.regex)) {
  //     event.preventDefault();
  //   }
  // }
  // // @HostListener('paste', ['$event'])
  // // onPaste(event: ClipboardEvent) {  
  // //   const clipboardData = event.clipboardData?.getData('text') || '';
  // //   if (!/^\d*\.?\d{0,2}$/.test(clipboardData)) { // Check if the pasted content is numeric
  // //     event.preventDefault(); // Block the paste if invalid
  // //   }
  // // }
}
