import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataService } from '@shared/async-services/data.service';
import { flatMap, Observable, switchMap } from 'rxjs';


import * as CryptoJS from 'crypto-js';
import { ListTable } from '@shared/models';


@Injectable({
  providedIn: 'root'
})
export class InsuranceService {
  encryptSecretKey = 'letalliance_1234';
  AesKey = "HR$2pIjHR$2pIj12";
  AesIV = "HR$2pIjHR$2pIj12";
  public addOnCRMBasePath = "CRM/";
  public addOnOperationBasePath = "operation/";
  public documentBasePath = 'Document/';
  public activityBasePath = 'Activity/';
  public addOnInsuranceBasePath = "Insurance/";
  public addOnConfigurationBasePath = "configuration/";
  public DocumentGenerationBasePath = "DocumentGeneration/";
  public DocumentManagerBasePath = "documentmanager/";
  public ReportBasePath = "report/";

  constructor(protected httpClient: HttpClient, protected dataService: DataService) { }

  public getEntityType(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetEntityType");
  }
  public getOccupation(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetOccupation");
  }

  public getPerilsList(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPerilsList/" + productId);
  }

  public getPaELPLRatesList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPaELPLRatesList");
  }
  public getPaLevelsList(paLevelsViewModel: any): Observable<any> {
    return this.dataService.post<any>(this.addOnInsuranceBasePath + "GetPaLevelsList", paLevelsViewModel);
  }
  public getPaBenefitsList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPaBenefitsList");
  }

  public getPaSelectionOptionList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPaSelectionOptionList");
  }

  public getPIOccupation(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPIOccupationList");
  }
  public getPiLimit(classOfOcc: string): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPiLimit/" + classOfOcc);
  }
  public getPiQuoteDashbordlist(policyId: any, isRENTran: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Pi/GetPiQuoteDashbordlist/" + policyId + "/" + isRENTran);
  }

  public getPiSOF(lstPISOFViewModel: any): Observable<any> {
    return this.dataService.post<any>(this.addOnInsuranceBasePath + 'GetPiSOF', lstPISOFViewModel);
  }

  public getProductUWQuestion(item: any, policyId: number): Observable<any> {
    return this.dataService.get(
      this.addOnInsuranceBasePath + "UnderWriterQuestion/GetProductUWQuestion/" + item + "/" + policyId);
  }

  public geProducts(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnInsuranceBasePath + 'InsuranceProduct/GetProducts');
  }
  public getAgent(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'agent/all');
  }
  public getAgentDetail(agentId): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'agent/' + agentId);
  }
  public ContactList(agentId): Observable<any> {
    return this.dataService.get(this.addOnCRMBasePath + 'AgentContact/ContactList/' + agentId);
  }
  public getPolicyType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnInsuranceBasePath + 'PolicyType');
  }

  public GetTransactionTypeList(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnInsuranceBasePath + 'GetTransactionTypeList');
  }

  public getPolicyStatus(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnInsuranceBasePath + 'PolicyStatus');
  }

  public GetActiveInsurer(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + 'GetActiveInsurer');
  }

  public GetPolicyTransactionTypes(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + 'GetPolicyTransactionTypes');
  }

  public GetOpenMarketTransactionTypes(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + 'GetOpenMarketTransactionTypes');
  }

  public getCompanyContact(): Observable<any> {
    return this.dataService.get(this.addOnConfigurationBasePath + 'ManageConfiguration/GetCompanyContact');
  }

  public getDiaryStatus(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnInsuranceBasePath + 'GetDiaryStatus');
  }
  public getInsurer(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnInsuranceBasePath + 'GetInsurer');
  }
  public getDiaryType(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnInsuranceBasePath + 'GetDiaryType');
  }
  public getCountyName(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnInsuranceBasePath + 'GetCountyName');
  }
  public getPOICountyName(productId: any = null): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnInsuranceBasePath + 'GetPOICountyName/' + productId);
  }
  public GetAllSystemAgent(): Observable<Array<ListTable>> {
    return this.dataService.get(this.addOnCRMBasePath + 'Agent/GetAllSystemAgent');
  }

  public postsearchpolicy(searchParameters: any): Observable<any> {
    return this.dataService.post<any>(this.addOnInsuranceBasePath + 'Policy/SearchPolicy', searchParameters);
  }

  public searchdiary(searchParameters: any): Observable<any> {
    return this.dataService.post<any>(this.addOnInsuranceBasePath + 'Diary/SearchDiary', searchParameters);
  }

  public searchRenewalLapsedDiary(searchParameters: any): Observable<any> {
    return this.dataService.post<any>(this.addOnInsuranceBasePath + 'Diary/SearchRenewalLapsedDiary', searchParameters);
  }
  public MakeCreditorPayment(objSave: any): Observable<any> {
    return this.dataService.post<any>(this.addOnInsuranceBasePath + 'Payment/MakeCreditorPayment', objSave);
  }

  public MakeOfficeAccountPayment(objSave: any): Observable<any> {
    return this.dataService.post<any>(this.addOnInsuranceBasePath + 'Payment/MakeOfficeAccountPayment', objSave);
  }
  public AddUpdateBankPeriodBalance(objSave: any): Observable<any> {
    return this.dataService.post<any>(this.addOnInsuranceBasePath + 'Account/AddUpdateBankPeriodBalance', objSave);
  }
  public DeleteBankPeriodBalance(acBankPeriodBalanceId: any): Observable<any> {
    return this.dataService.delete<any>(this.addOnInsuranceBasePath + 'Account/DeleteBankPeriodBalance/' + acBankPeriodBalanceId);
  }
  public GetAcBankPeriodBalance(): Observable<any> {
    return this.dataService.get<any>(this.addOnInsuranceBasePath + 'Account/GetAcBankPeriodBalance');
  }

  public GetMonthEndClientAccountReport(objSave: any): Observable<any> {
    return this.dataService.post<any>(this.ReportBasePath + 'Report/GetMonthEndClientAccountReport', objSave);
  }

  public GetSalesReport(objSave: any): Observable<any> {
    return this.dataService.post<any>(this.ReportBasePath + 'Report/GetSalesReport', objSave);
  }

  public GetDebtorCreditorBankClearanceData(objSave: any): Observable<any> {
    return this.dataService.post<any>(this.ReportBasePath + 'Report/GetDebtorCreditorBankClearanceData', objSave);
  }
  public GetMonthEndClientAccountCompanyPaymentsOverPaidOnDate(objSave: any): Observable<any> {
    return this.dataService.post<any>(this.ReportBasePath + 'Report/GetMonthEndClientAccountCompanyPaymentsOverPaidOnDate', objSave);
  }
  public GetMonthEndClientAccountSujBrokerPaymentsOverPaidOnDate(objSave: any): Observable<any> {
    return this.dataService.post<any>(this.ReportBasePath + 'Report/GetMonthEndClientAccountSujBrokerPaymentsOverPaidOnDate', objSave);
  }
  public GetMonthEndClientAccountTransferPaymentOverPaid(objSave: any): Observable<any> {
    return this.dataService.post<any>(this.ReportBasePath + 'Report/GetMonthEndClientAccountTransferPaymentOverPaid', objSave);
  }

  public saveElPL(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote", objSave, null);
  }
  public saveFcc(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/Fcc", objSave, null);
  }
  public saveCar(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/Car", objSave, null);
  }
  public saveSb(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/Sb", objSave, null);
  }
  public saveRei(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/Rei", objSave, null);
  }
  public saveReiMTA(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "MTA/Rei", objSave, null);
  }
  public saveReiREN(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Renewal/Rei", objSave, null);
  }
  public saveFccMTA(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "MTA/Fcc", objSave, null);
  }
  public saveFccREN(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Renewal/Fcc", objSave, null);
  }
  public saveShop(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/Shop", objSave, null);
  }
  public saveShopMTA(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "MTA/Shop", objSave, null);
  }
  public saveShopREN(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Renewal/Shop", objSave, null);
  }
  public saveMTAQuote(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "MTA/ELPL", objSave, null);
  }
  public updateMTAPolicy(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "MTA", objSave, null);
  }
  public saveRenewalQuote(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Renewal/CreateRenewalPolicy", objSave, null);
  }
  public updateRenewalQuote(objSave: any): Observable<any> {
    return this.dataService.put(
      this.addOnInsuranceBasePath + "Renewal/RenewalPolicyupdate", objSave, null);
  }
  public savePublicHouseRestaurant(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/Lei", objSave, null);
  }
  public savePublicHouseRestaurantMTA(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "MTA/Lei", objSave, null);
  }
  public savePublicHouseRestaurantREN(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Renewal/Lei", objSave, null);
  }
  public saveOffice(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/Off", objSave, null);
  }
  public SaveMessageAlertSite(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "MessageAlertSite/SaveMessageAlertSite", objSave, null);
  }
  public GetMessageAlertSite(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "MessageAlertSite/GetMessageAlertSite");
  }
  public saveOfficeMTA(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "MTA/Off", objSave, null);
  }
  public saveOfficeREN(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Renewal/Off", objSave, null);
  }
  public createDirectRenewalQuote(policyId: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Renewal/CreateDirectRenewalPolicy/" + policyId, policyId, null);
  }
  public saveSpecialTermInfo(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "PolicyPremium/SaveSpecialTermInfo", objSave, null);
  }

  public ProcessBrokerRemittance(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Payment/ProcessBrokerRemittance", objSave);
  }
  public updateSpecialTerm(objSave: any): Observable<any> {
    return this.dataService.put(
      this.addOnInsuranceBasePath + "PolicyPremium/UpdateSpecialTerm", objSave, null);
  }
  public saveSpecialTermCC(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "PolicyPremium/SaveSpecialTermCC", objSave, null);
  }
  public saveSpecialTermSB(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "PolicyPremium/SaveSpecialTermSB", objSave, null);
  }
  public saveSpecialTermCar(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "PolicyPremium/SaveSpecialTermCar", objSave, null);
  }
  public saveAmendTermInfo(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "PolicyPremium/SaveAmendTermInfo", objSave, null);
  }
  public saveAmendTermCC(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "PolicyPremium/SaveAmendTermCC", objSave, null);
  }
  public saveAmendTermSB(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "AmendTerms/SaveAmendTermSB", objSave, null);
  }
  public saveAmendTermCar(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "AmendTerms/SaveAmendTermCar", objSave, null);
  }
  public saveAmendTermBBG(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "AmendTerms/SaveAmendTermBBG", objSave, null);
  }
  public saveAmendTermPa(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "AmendTerms/SaveAmendTermPa", objSave, null);
  }
  public savePolicyNote(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/SavePolicyNote", objSave, null);
  }
  public saveNonRenewNote(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/SaveNonRenewPolicyNote", objSave, null);
  }
  public SaveNonRenewPolicyNotePI(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/SaveNonRenewPolicyNotePI", objSave, null);
  }
  public SaveNonRenewPolicyNotePA(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/SaveNonRenewPolicyNotePA", objSave, null);
  }
  public SaveNonRenewPolicyNoteBbg(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/SaveNonRenewPolicyNoteBbg", objSave, null);
  }
  public SaveNonRenewPolicyNotePOI(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Quote/SaveNonRenewPolicyNotePOI", objSave, null);
  }
  public savePolicyDiaryNote(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Diary/savePolicyDiaryNote", objSave, null);
  }

  public saveCancelPolicy(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/CancelPolicy", objSave, null);
  }
  public saveCancelPolicyCar(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/CancelPolicy/Car", objSave, null);
  }
  public saveCancelPolicyRei(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/CancelPolicy/Rei", objSave, null);
  }
  public saveCancelPolicyPI(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/CancelPolicy/PI", objSave, null);
  }
  public saveCancelPolicyBbg(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/CancelPolicy/BBG", objSave, null);
  }
  public saveCancelPolicyPA(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/CancelPolicy/PA", objSave, null);
  }
  public saveCancelPolicyPOI(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/CancelPolicy/POI", objSave, null);
  }
  public saveCancelPolicySb(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/CancelPolicy/Sb", objSave, null);
  }
  public saveCancelPolicyCC(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/CancelPolicyCC", objSave, null);
  }
  public saveNTUPolicy(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/NTUPolicy", objSave, null);
  }
  public updateQuote(objSave: any): Observable<any> {
    return this.dataService.put(
      this.addOnInsuranceBasePath + "Quote", objSave, null);
  }
  public GetQuoteDetailsByPolicyId(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Quote/GetQuoteDetailsByPolicyId/" + policyId);
  }

  public GetQuoteListByPolicyId(policyId: any, isRENTran: any, isViewQuote: any = false, parentPolicyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Quote/GetQuoteListByPolicyId/" + policyId + "/" + isRENTran + "/" + isViewQuote + "/" + parentPolicyId);
  }
  public GetQuoteListForPolicyDashbord(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Quote/GetQuoteListForPolicyDashbord/" + policyId);
  }
  public GetQuoteDetailsForPolicyDashbord(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Quote/GetQuoteDetailsForPolicyDashbord/" + policyId);
  }

  public getCommQuoteDashbordlist(policyId: any, isRENTran: any, isViewQuote: any = false, parentPolicyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "CommericalQuote/GetCommQuoteDashbordlist/" + policyId + "/" + isRENTran + "/" + isViewQuote + "/" + parentPolicyId);
  }
  public getCommQuoteDashbordByPolicyId(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "CommericalQuote/GetCommQuoteDashbordByPolicyId/" + policyId);
  }
  public GetCommPolicyDashbordlist(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "CommericalQuote/GetCommPolicyDashbordlist/" + policyId);
  }
  public GetCommPolicyDashbordByPolicyId(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "CommericalQuote/GetCommPolicyDashbordByPolicyId/" + policyId);
  }
  public GetSpecifiedItemListByPolicyId(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "PolicySpecifiedItem/GetSpecifiedItemListByPolicyId/" + policyId);
  }
  public createPolicy(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy", objSave);
  }
  public createPolicyCAR(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Cover/CAR", objSave);
  }
  public CreatePolicyRei(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Cover/REI", objSave);
  }
  public InceptMTAPolicyRei(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Cover/REI/MTA", objSave);
  }
  public inceptRenewalPolicyRei(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Cover/REI/REN", objSave);
  }

  public createPolicySB(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Cover/SB", objSave);
  }
  public inceptMTAPolicySB(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Cover/SB/MTA", objSave);
  }
  public inceptMTAPolicy(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/InceptMTAPolicy", objSave);
  }

  public inceptRenewalPolicy(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/InceptRenewalPolicy", objSave);
  }
  public CreatePolicyCC(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/CreatePolicyCC", objSave);
  }
  public InceptMTAPolicyCC(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/InceptMTAPolicyCC", objSave);
  }
  public InceptRenewalPolicyCC(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Policy/InceptRenewalPolicyCC", objSave);
  }

  public GetPolicyCover(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Quote/GetPolicyCover/" + policyId);

  }
  public GetPoPolicyCover(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Po/GetPoPolicyCover/" + policyId);

  }
  public GetAgentPolicyHandler(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Quote/GetAgentPolicyHandler/" + policyId);
  }
  public AddUpdateAgentPolicyHandler(objSave: any): Observable<any> {
    if (objSave.claimHandlerId > 0) { return this.dataService.put(this.addOnInsuranceBasePath + "Quote/UpdateAgentPolicyHandler", objSave, null); }
    else {
      return this.dataService.post(this.addOnInsuranceBasePath + "Quote/CreateAgentPolicyHandler", objSave, null);
    }
  }
  public GetAllReferralListELPL(prodcode, modelData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "QuoteReferral/GetAllReferral/" + prodcode, modelData, null);
  }
  public GetAllReferralListFcc(modelData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "QuoteReferral/GetAllReferral/Fcc", modelData, null);
  }
  public GetAllReferralListLei(modelData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "QuoteReferral/GetAllReferral/Lei", modelData, null);
  }
  public GetAllReferralListOff(modelData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "QuoteReferral/GetAllReferral/Off", modelData, null);
  }
  public GetAllReferralListShop(modelData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "QuoteReferral/GetAllReferral/Shop", modelData, null);
  }
  public GetAllReferralListPO(modelData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "QuoteReferral/GetAllReferral/PO", modelData, null);
  }
  public GetQuoteBreakDown(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Quote/GetQuoteBreakDown/" + policyId);
  }
  public GetEmailDocumentData(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "PolicyDocument/GetEmailDocumentData/" + policyId);
  }
  public GetQuoteBreakDownSB(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Quote/GetQuoteBreakDownSB/" + policyId);
  }
  public GetQuoteAmendTermsCC(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Quote/GetQuoteAmendTermsCC/" + policyId);
  }
  public GetQuoteAmendTermsSB(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "AmendTerms/GetQuoteAmendTermsSB/" + policyId);
  }
  public GetQuoteAmendTermsCarAndPI(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "AmendTerms/GetQuoteAmendTermsCarAndPI/" + policyId);
  }
  public GetQuoteAmendTermsBbg(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Quote/GetQuoteAmendTermsBbg/" + policyId);
  }
  public GetQuoteAmendTermsPa(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "AmendTerms/GetQuoteAmendTermsPa/" + policyId);
  }
  public getPolicyPremium(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "PolicyPremium/policyid=" + policyId);
  }

  public getPolicyPremiumOverride(policies: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "PolicyPremium/GetPolicyPremiumOverride", policies);
  }
  public getPolicyPremiumOverrideCar(policies: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "PolicyPremium/GetPolicyPremiumOverrideCar", policies);
  }
  public getPolicyPremiumOverrideSB(policies: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "PolicyPremium/GetPolicyPremiumOverrideSB", policies);
  }
  public GenerateRenewalDocument(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "PolicyDocument/GenerateRenewalDocument", objSave);
  }
  public GetEmailDataForPoiByAgentId(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "PolicyDocument/GetEmailDataForPoiByAgentId", objSave);
  }
  public sendEmailDocuments(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "PolicyDocument/SendEmailDocuments", objSave);
  }
  public sendRenewalQuoteEmailDocuments(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "PolicyDocument/SendRenewalQuoteEmailDocuments", objSave);
  }
  public addPolicyPrincipal(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "PolicyNamedPrincipal", objSave, null);
  }

  public GetNamedPrincipalList(policyId: number, type: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "PolicyNamedPrincipal/GetNamedPrincipalList/" + policyId + "/" + type);
  }
  public GetNamedPrincipalById(NamedPrincipalId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "PolicyNamedPrincipal/GetNamedPrincipalById", NamedPrincipalId);
  }

  public UpdatePolicyPrinciple(data: any): Observable<any> {
    return this.dataService.put(this.addOnInsuranceBasePath + "PolicyNamedPrincipal/Put/" + data.namedPrincipalId, data);
  }
  public Delete(NamedprincipalId: any): Observable<any> {
    return this.dataService.delete(this.addOnInsuranceBasePath + "PolicyNamedPrincipal/Delete/" + NamedprincipalId);
  }
  public DeleteSpecialTerm(specialTermId: any): Observable<any> {
    return this.dataService.delete(this.addOnInsuranceBasePath + "PolicyPremium/DeleteSpecialTerm/" + specialTermId);
  }
  public DeleteSpecialTermSB(specialTermId: any): Observable<any> {
    return this.dataService.delete(this.addOnInsuranceBasePath + "PolicyPremium/DeleteSpecialTermSB/" + specialTermId);
  }
  public DeleteSpecialTermCC(specialTermId: any): Observable<any> {
    return this.dataService.delete(this.addOnInsuranceBasePath + "PolicyPremium/DeleteSpecialTermCC/" + specialTermId);
  }
  public GetSpecialTermList(policyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "PolicyPremium/GetSpecialTermList/" + policyId);
  }

  public GetDairyById(dairyId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Diary/GetById/" + dairyId);
  }
  public updateDiary(objSave: any): Observable<any> {
    return this.dataService.put(this.addOnInsuranceBasePath + "Diary", objSave, null);
  }


  public GetDashboardSummary(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "PolicyDashboard/GetDashboardSummary");
  }

  public GetClaimStatus(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetClaimStatus");
  }

  public GetClaimNaturelist(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetClaimNaturelist");
  }
  public GetBankPaymentInOutRequestFormList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetBankPaymentInOutRequestFormList");
  }
  public GetAgentNameAndCode(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetAgentNameAndCode");
  }

  public GetAgentName(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Additional/GetAgentName");
  }

  public GetClaimNaturelistByProductId(productId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetClaimNaturelistByProductId/" + productId);
  }
  public GetClaimNoteType(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetClaimNoteType");
  }
  public GetClaimPerillistByClaimNature(claimNatureId: number): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetClaimPerillistByClaimNature/" + claimNatureId);
  }

  public AddUpdateClaim(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Claim/AddUpdateClaim", objSave);
  }
  public AddImportedClaims(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Claim/AddImportedClaims", objSave);
  }
  public UpdateImportClaim(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Claim/UpdateImportClaim", objSave);
  }

  public AddDuplicatClaim(claimId: any, policyId: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Claim/AddDuplicatClaim/" + claimId + "/" + policyId, null);
  }

  public GetPolicyDetailsForClaim(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Claim/GetPolicyDetailsForClaim/" + policyId);
  }

  public CheckIsAnyClaimForPolicy(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Claim/CheckIsAnyClaimForPolicy/" + policyId);
  }
  public CheckIsAnyClaimForPolicyInitiator(policyInitiatorId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Claim/CheckIsAnyClaimForPolicyInitiator/" + policyInitiatorId);
  }
  public ImportMIClaims(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Claim/ImportMIClaims", objSave);
  }

  public ValidateCSVFile(file: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Claim/ValidateCSVFile", file);
  }

  public GetClaimClaimantList(claimId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "ClaimClaimant/GetClaimClaimantList/" + claimId);
  }

  public AddUpdateClaimClaimant(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "ClaimClaimant/AddUpdateClaimClaimant", objSave);
  }

  public AddUpdateClaimNote(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "ClaimNote/AddUpdateClaimNote", objSave);
  }

  public GetClaimNotes(claimId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "ClaimNote/GetClaimNotes/" + claimId);
  }

  public DeleteClaimNote(claimNoteId: any): Observable<any> {
    return this.dataService.delete(this.addOnInsuranceBasePath + "ClaimNote/DeleteClaimNote/" + claimNoteId);
  }

  public DeleteClaimClaimant(claimClaimantId: any): Observable<any> {
    return this.dataService.delete(this.addOnInsuranceBasePath + "ClaimClaimant/DeleteClaimClaimant/" + claimClaimantId);
  }

  public GetClaimListByPolicyId(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Claim/GetClaimListByPolicyId/" + policyId);
  }

  public GetClaimListByPolicyInitiatorId(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Claim/GetClaimListByPolicyInitiatorId/" + policyId);
  }


  public GetDuplicateClaimListByPolicyId(policyInitiatorId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Claim/GetDuplicateClaimListByPolicyId/" + policyInitiatorId);
  }

  public GetDuplicateClaimListByPolicyInitiatorId(policyInitiatorId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Claim/GetDuplicateClaimListByPolicyInitiatorId/" + policyInitiatorId);
  }

  public GetClaimDetail(claimId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Claim/GetClaimDetail/" + claimId);
  }

  public GetPolicyTotalClaimAmounts(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "ClaimClaimant/GetPolicyTotalClaimAmounts/" + policyId);
  }
  public GetPolicyTotalClaimAmountsByPolicyInitiatorId(policyInitiatorId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "ClaimClaimant/GetPolicyTotalClaimAmountsByPolicyInitiatorId/" + policyInitiatorId);
  }

  public GetPolicyTotalClaimAmountByClaim(claimId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "ClaimClaimant/GetPolicyTotalClaimAmountByClaim/" + claimId);
  }


  public GetActiveNotification(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Announcement/GetActiveNotification");
  }

  public SaveAnnouncement(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Announcement/SaveAnnouncement", objSave);
  }

  public UpdateAnnouncement(objSave: any): Observable<any> {
    return this.dataService.put(this.addOnInsuranceBasePath + "Announcement/UpdateAnnouncement", objSave);
  }

  public GetAnnouncementById(announcementId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Announcement/GetAnnouncementById/" + announcementId);
  }

  public GetAllAnnouncement(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Announcement/GetAllAnnouncement", objSave);
  }

  public SaveOpenTransaction(objSave: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Account/SaveOpenTransaction", objSave);
  }

  public UpdateAnnouncementStatus(announcementId: any): Observable<any> {
    return this.dataService.put(
      this.addOnInsuranceBasePath + "Announcement/UpdateAnnouncementStatus/" + announcementId, null);
  }

  public GetAccountTransactionListAgent(policyInitiatorId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Account/GetAccountTransactionListAgent/" + policyInitiatorId);
  }

  public GetAccountTransactionListBackOffice(policyInitiatorId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Account/GetAccountTransactionListBackOffice/" + policyInitiatorId);
  }

  public GetAccountTransactionListAgentByPolicyId(policyId: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Account/GetAccountTransactionListAgentByPolicyId", policyId);
  }

  public GetAccountTransactionListBackOfficeByPolicyId(policyId: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Account/GetAccountTransactionListBackOfficeByPolicyId", policyId);
  }

  public UpdateTransaction(obj: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Account/UpdateTransaction", obj);
  }

  public AddTransactionNote(obj: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Account/AddTransactionNote", obj);
  }

  public GetAccountTransactionDetail(transactionId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Account/GetAccountTransactionDetail/" + transactionId);
  }
  public GetAccountTransactionDetailAgent(transactionId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Account/GetAccountTransactionDetailAgent/" + transactionId);
  }

  public GetTransactionDetailsReport(obj: any): Observable<any> {
    return this.dataService.post(this.ReportBasePath + "Report/GetTransactionDetailsReport", obj);
  }
  public GeCancellationNoticeReportTransactions(obj: any): Observable<any> {
    return this.dataService.post(this.ReportBasePath + "Report/GeCancellationNoticeReportTransactions", obj);
  }
  public GetOfficeAccountReconciliationPaymentSummary(obj: any): Observable<any> {
    return this.dataService.post(this.ReportBasePath + "Report/GetOfficeAccountReconciliationPaymentSummary", obj);
  }
  public GetCpBankReconciliationPaymentSummaryReport(obj: any): Observable<any> {
    return this.dataService.post(this.ReportBasePath + "Report/GetCpBankReconciliationPaymentSummaryReport", obj);
  }
  public GetCommissionStatement(obj: any): Observable<any> {
    return this.dataService.post(this.ReportBasePath + "Report/GetCommissionStatement", obj);
  }
  public GetBankPaymentReport(obj: any): Observable<any> {
    return this.dataService.post(this.ReportBasePath + "Report/GetBankPaymentReport", obj);
  }
  public GetAgedCreditorsTransactionList(obj: any): Observable<any> {
    return this.dataService.post(this.ReportBasePath + "Report/GetAgedCreditorsTransactionList", obj);
  }
  public GetAgentDebtSummaryReport(obj: any): Observable<any> {
    return this.dataService.post(this.ReportBasePath + "Report/GetAgentDebtSummaryReport", obj);
  }
  public GetPaymentAndTransactionsDetailsReport(obj: any): Observable<any> {
    return this.dataService.post(this.ReportBasePath + "Report/GetPaymentAndTransactionsDetailsReport", obj);
  }

  public UploadFileToS3(objFileInfo: any): Observable<any> {
    return this.dataService.post(
      this.documentBasePath + 'Document/UploadDocument',
      objFileInfo
    );
  }

  public DownloadFileFromS3(objFileInfo: any): Observable<any> {

    let chain: Observable<any> = this.GetDocumentDownloadKey(objFileInfo);
    return chain
      .pipe(
        switchMap(result1Data =>
          this.dataService.postBlob(
            this.documentBasePath + 'Document/DownloadDocumentpublic',
            result1Data.payload
          )
        )
      )
     
  }

  public GetDocumentDownloadKey(objData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + 'PolicyDocument/GenerateDocKey', objData);
  }
  public GetDocumentData(objData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + 'PolicyDocument/GetDocumentData', objData);
  }


  public GetAndDownloadDocument(objData: any): Observable<any> {
    return this.dataService.postBlob(this.addOnInsuranceBasePath + 'PolicyDocument/DownloadDocument', objData);
  }

  public GetAndDownloadDocumentForExportEmail(objData: any): Observable<any> {
    return this.dataService.postBlob(this.addOnInsuranceBasePath + 'ExportEmail/ExportEmailDownloadDocument', objData);
  }


  public GetClaimsForReport(objData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + 'Claim/GetClaimsForReport', objData);
  }
  public GetClaimsReport(objData: any): Observable<any> {
    return this.dataService.post(this.ReportBasePath + 'report/GetClaimReport', objData);
  }

  public GenerateDocument(objFileInfo: any): Observable<any> {
    return this.dataService.postBlob(
      this.DocumentManagerBasePath + 'DocumentGenerationQueue/GenerateDocument',
      objFileInfo
    );
  }

  public GetConfigurationListOnlyEditable(companyId: any): Observable<any> {
    return this.dataService.get(
      this.addOnConfigurationBasePath + "ManageConfiguration/GetConfigurationListOnlyEditable/" + companyId);
  }

  public UpdateConfigurationList(objSave: any): Observable<any> {
    return this.dataService.put(
      this.addOnConfigurationBasePath + "ManageConfiguration/UpdateConfiguration", objSave);
  }

  public getAllDocumentGroup(): Observable<any> {
    return this.dataService.get(
      this.addOnInsuranceBasePath + "DocumentGroup/GetAllDocumentGroups"
    );
  }

  public GetClaimReportFormData(): Observable<any> {
    return this.dataService.get(
      this.addOnInsuranceBasePath + "GetClaimReportFormData"
    );
  }

  public getAllDocumentFile(): Observable<any> {
    return this.dataService.get(
      this.addOnInsuranceBasePath + "DocumentFile/GetAllDocumentfiles"
    );
  }


  public GetCancelledPolicyLisyByMonth(month: any, year: any): Observable<any> {
    return this.dataService.get(
      this.addOnInsuranceBasePath + "Policy/GetCancelledPolicyLisyByMonth/" + month + "/" + year
    );
  }


  public SaveDocumentFile(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "DocumentFile/SaveDocumentFile",
      objSave
    );
  }
  public MakeBankPayment(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Payment/MakeBankPayment",
      objSave
    );
  }
  public MakeBankInOutPayment(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Payment/MakeBankInOutPayment",
      objSave
    );
  }

  public DeleteDocumentFile(documentFileId: any): Observable<any> {
    return this.dataService.delete(
      this.addOnInsuranceBasePath + "DocumentFile/DeleteDocumentFile/" + documentFileId
    );
  }
  public LogPolicyDocumentDownload(policyId: any): Observable<any> {
    return this.dataService.get(
      this.addOnInsuranceBasePath + "SelfBuild/LogPolicyDocumentDownload/" + policyId
    );
  }
  public GetScheduleDocuments(policyId: any): Observable<any> {
    return this.dataService.get(
      this.addOnInsuranceBasePath + "PolicyDocument/GetScheduleDocuments/" + policyId
    );
  }

  public ArchiveDocumentFile(documentFile: any): Observable<any> {
    return this.dataService.put(
      this.addOnInsuranceBasePath + 'DocumentFile/ArchiveDocumentFile',
      documentFile
    );
  }
  //---------------------Combined  Commercial Policy Methods--------------------
  public GetMoneyLimit(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetMoneyLimit/" + productId);
  }
  public GetRoofConstFull(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetRoofConstFull/" + productId);
  }
  public GetRoofConstAge(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetRoofConstAge/" + productId);
  }
  public GetAreaCode(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetAreaCode");
  }
  public GetRoofConst(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetRoofConst/" + productId);
  }
  public GetAlarm(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetAlarm/" + productId);
  }
  public GetAlarmBbg(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetAlarmBbg/" + productId);
  }
  public GetTenure(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetTenure/" + productId);
  }
  public getBusinessDescription(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetBusinessDescription/" + productId);
  }
  public GetCCBusinessDescription(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetCCBusinessDescription/" + productId);
  }

  public GetAgeOrProperty(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetAgeOrProperty/" + productId);
  }
  public GetTradeType(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetTradeType/" + productId);
  }
  public GetRoofConstNonStandard(productId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetRoofConstNonStandard/" + productId);
  }

  public GetRoofConstructionByPolicyId(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "QuoteAdditionalDetails/GetPolicyRoofConstructionDetail/" + policyId);
  }
  public GetBusinessInterruptionByPolicyId(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "QuoteAdditionalDetails/GetPolicyBusinessInterruptionDetail/" + policyId);
  }
  public GetPolicySecurityByPolicyId(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "QuoteAdditionalDetails/GetPolicySecurityDetail/" + policyId);
  }

  public GetPropertyCompletion(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPropertyCompletion");
  }
  public GetArea(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetArea");
  }
  public GetProjectType(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetProjectType");
  }
  public GetApproxYearConst(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetApproxYearConst");
  }


  public GetPOIIndemnityPeriod(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOIIndemnityPeriod");
  }

  public GetPOIRentCoverPercentage(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOIRentCoverPercentage");
  }

  public GetPOIResidentialUnitTypeList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOIResidentialUnitTypeList");
  }

  public GetFireWarningList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetFireWarningList");
  }
  public GetPOIAlarmList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOIAlarmList");
  }

  public GetRoofPercentagePropertyList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOIFlatRoofPerc");
  }



  public GetPOIProtectedFeaturesTypeList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOIProtectedFeaturesTypeList");
  }

  public GetPOIConstructionRoofList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOIConstructionRoofList");
  }

  public GetPreviousPropertyOwnerInsurer(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPreviousPropertyOwnerInsurer");
  }







  public GetLocationsPropertyUse(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetLocationsPropertyUse");
  }

  public GetPOIResidentialBuildingTypeList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOIResidentialBuildingTypeList");
  }
  public GetConcreteTimber(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetConcreteTimber");
  }
  public GetResidentialPropType(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetResidentialPropType");
  }
  public GetListPropCommType(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetListPropCommType");
  }


  public GetListPropPOICommType(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetListPOIPropCommType");
  }
  public GetLstPropPOICommUsage(propCommTypeId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetLstPOIPropCommUsage/" + propCommTypeId);
  }

  public GetLstPropCommUsage(propCommTypeId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetLstPropCommUsage/" + propCommTypeId);
  }
  public GetLstPropCommUsageAll(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetLstPropCommUsageAll");
  }

  public GetLstPOIPropCommUsageAll(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetLstPOIPropCommUsageAll");
  }
  public GetMaximumIndemnityPeriod(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetMaximumIndemnityPeriod");
  }
  public GetLstPropertyClaimType(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetLstPropertyClaimType");
  }
  public GetPropertyClaimStatus(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPropertyClaimStatus");
  }
  public GetPropertyClaimSection(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPropertyClaimSection");
  }
  public GetAgeProperty(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetAgeProperty");
  }
  public GetLstCARBusiness(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetLstCARBusiness");
  }
  public saveCarMTA(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "MTA/Car", objSave, null);
  }
  public saveCarREN(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Renewal/Car", objSave, null);
  }
  public InceptMTAPolicyCAR(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Cover/CAR/MTA", objSave);
  }
  public InceptRenewalPolicyCAR(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "Cover/CAR/REN", objSave);
  }
  public GetCarQuoteListForPolicyDashboard(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "CAR/GetCarQuoteListForPolicyDashboard/" + policyId);
  }
  public GetCarPolicyDashboardDetail(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "CAR/GetCarPolicyDashboardDetail/" + policyId);
  }
  public GetAllReferralListCar(modelData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "QuoteReferral/GetAllReferral/Car", modelData, null);
  }
  public GetAllReferralListSB(modelData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "QuoteReferral/GetAllReferral/SB", modelData, null);
  }



  public SavePOcoverDetails(modelData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Cover/Po", modelData);
  }
  public SavePOMTAcoverDetails(modelData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Cover/Po/MTA", modelData);
  }
  public SavePORENcoverDetails(modelData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Cover/Po/REN", modelData);
  }


  public GetPOIPolHolderTypeList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOIPolHolderTypeList");
  }

  public GetProductSettingPO(SettingKey: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetProductSettingPO/" + SettingKey);
  }
  public GetProductSetting(SettingKey: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetProductSetting/" + SettingKey);
  }


  public GetPOITitleList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOITitleList");
  }
  public GetPOIAdditionalProposerType(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOIAdditionalProposerType");
  }

  public GetPOIOrganisations(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOIOrganisations");
  }

  public GetPOIConstTypeList(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetPOIConstTypeList");
  }

  public SaveQuotePoi(data: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Quote/Poi", data);
  }

  public SaveMTAQuotePoi(data: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "MTA/Poi", data);
  }
  public MakeBrokerPayment(data: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Payment/MakeBrokerPayment", data);
  }
  public MakeAxaReconciliationPayment(data: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Payment/MakeAxaReconciliationPayment", data);
  }

  public SaveRENQuotePoi(data: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "Renewal/Poi", data);
  }

  public GetPoQuoteDetails(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Po/GetPoQuoteDetails/" + policyId);
  }

  public SaveBinderControl(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnInsuranceBasePath + "InsurerBinder/SaveBinderControl", objSave);
  }
  public UpdateBinderControl(objSave: any): Observable<any> {
    return this.dataService.put(
      this.addOnInsuranceBasePath + "InsurerBinder/UpdateBinderControl", objSave);
  }

  public GetBinderForEdit(binderId: any): Observable<any> {
    return this.dataService.get(
      this.addOnInsuranceBasePath + "InsurerBinder/GetBinderForEdit/" + binderId);
  }
  public GetInsurerBinderHistoryList(binderId: any): Observable<any> {
    return this.dataService.get(
      this.addOnInsuranceBasePath + "InsurerBinder/GetInsurerBinderHistoryList/" + binderId);
  }

  public DeleteBinder(insurerBinderId: any): Observable<any> {
    return this.dataService.delete(
      this.addOnInsuranceBasePath + "InsurerBinder/Delete/" + insurerBinderId);
  }

  public GetExpiringBindersintheNext45days(): Observable<any> {
    return this.dataService.get(
      this.ReportBasePath + "report/GetListofExpiringBinders");
  }
  public CheckIsQuoteClosed(policyId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Quote/IsQuoteClosed/" + policyId);
  }
  public ReportDownloadDocument(objData: any): Observable<any> {
    return this.dataService.postBlob(this.ReportBasePath + 'report/ReportDownloadDocument', objData);
  }

  public ValidteExportEmailDocumentData(qurl: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "ExportEmail/ValidteExportEmailDocumentData/" + qurl);
  }
  public ValidateRedirectQuoteData(qurl: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "ExportEmail/ValidateRedirectQuoteData/" + qurl);
  }
  public ReGeneratePolicyDocument(modelData: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "PolicyDocument/RegeneratePolicyDocument", modelData);
  }
  public DropMtaQuote(qouteIdList: any): Observable<any> {
    return this.dataService.post(this.addOnInsuranceBasePath + "MTA/DropMta", qouteIdList);
  }

  public GetEmailTemplateType(): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "GetEmailTemplateType");
  }
  public ModiFyBankDate(objSave: any): Observable<any> {
    return this.dataService.post<any>(this.addOnInsuranceBasePath + 'Payment/ModiFyBankDate', objSave);
  }
  //----------------------END Combined Commercial Policy-------------------------
  public encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        CryptoJS.enc.Utf8.parse(this.encryptSecretKey),
        {
          keySize: 128 / 8,
          iv: CryptoJS.enc.Utf8.parse(this.encryptSecretKey),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
    } catch (e) {
      console.log(e);
    }
  }
  public decryptAndParseQueryString(enceyptedData: string) {
    if (enceyptedData === undefined || enceyptedData === null) {
      return;
    }
    const decryptparams = this.decryptData(enceyptedData);
    let policyId;
    let policyInitiatorId;
    let policyStatus;
    let isAddMTA;
    let isDisableScreen;
    let isRenewed;
    let productCode;
    let isViewQuote;
    let parentPolicyId;
    let selectedPolicyIdForView;
    let sourcePolicyId;
    let isViewSubQuote;
    let accontType;
    let bankId;
    let activePolicyId;
    let acPaymentId;
    let brokerRemittanceId;
    let agentId;
    let exactdate;
    let backBtnText;
    let backBtnPath;
    if (decryptparams.policyId !== undefined && decryptparams.policyId !== null) {
      policyId = parseInt(decryptparams.policyId);
    }
    if (decryptparams.policyInitiatorId !== undefined && decryptparams.policyInitiatorId !== null) {
      policyInitiatorId = parseInt(decryptparams.policyInitiatorId);
    }
    if (decryptparams.policyStatus !== undefined && decryptparams.policyStatus !== null) {
      policyStatus = parseInt(decryptparams.policyStatus);
    }
    if (decryptparams.isAddMTA !== undefined && decryptparams.isAddMTA !== null) {
      isAddMTA = decryptparams.isAddMTA;
    }
    if (decryptparams.isDisableScreen !== undefined && decryptparams.isDisableScreen !== null) {
      isDisableScreen = decryptparams.isDisableScreen;
    }
    if (decryptparams.isRenewed !== undefined && decryptparams.isRenewed !== null) {
      isRenewed = decryptparams.isRenewed;
    }
    if (decryptparams.productCode !== undefined && decryptparams.productCode !== null) {
      productCode = decryptparams.productCode;
    }
    if (decryptparams.isViewQuote !== undefined && decryptparams.isViewQuote !== null) {
      isViewQuote = decryptparams.isViewQuote;
    }
    if (decryptparams.selectedPolicyIdForView !== undefined && decryptparams.selectedPolicyIdForView !== null) {
      selectedPolicyIdForView = decryptparams.selectedPolicyIdForView;
    }
    if (decryptparams.sourcePolicyId !== undefined && decryptparams.sourcePolicyId !== null) {
      sourcePolicyId = decryptparams.sourcePolicyId;
    }
    if (decryptparams.parentPolicyId !== undefined && decryptparams.parentPolicyId !== null) {
      parentPolicyId = decryptparams.parentPolicyId;
    }
    if (decryptparams.isViewSubQuote !== undefined && decryptparams.isViewSubQuote !== null) {
      isViewSubQuote = decryptparams.isViewSubQuote;
    }
    if (decryptparams.activePolicyId !== undefined && decryptparams.activePolicyId !== null) {
      activePolicyId = decryptparams.activePolicyId;
    }
    if (decryptparams.acPaymentId !== undefined && decryptparams.acPaymentId !== null) {
      acPaymentId = decryptparams.acPaymentId;
    }
    if (decryptparams.bankId !== undefined && decryptparams.bankId !== null) {
      bankId = decryptparams.bankId;
    }
    if (decryptparams.accontType !== undefined && decryptparams.accontType !== null) {
      accontType = decryptparams.accontType;
    }
    if (decryptparams.brokerRemittanceId !== undefined && decryptparams.brokerRemittanceId !== null) {
      brokerRemittanceId = decryptparams.brokerRemittanceId;
    }
    if (decryptparams.agentId !== undefined && decryptparams.agentId !== null) {
      agentId = decryptparams.agentId;
    }
    if (decryptparams.exactdate !== undefined && decryptparams.exactdate !== null) {
      exactdate = decryptparams.exactdate;
    }
    if (decryptparams.backBtnText !== undefined && decryptparams.backBtnText !== null) {
      backBtnText = decryptparams.backBtnText;
    }
    if (decryptparams.backBtnPath !== undefined && decryptparams.backBtnPath !== null) {
      backBtnPath = decryptparams.backBtnPath;
    }
    return {
      policyId: policyId,
      policyInitiatorId: policyInitiatorId,
      policyStatus: policyStatus,
      isAddMTA: isAddMTA,
      isDisableScreen: isDisableScreen,
      isRenewed: isRenewed,
      productCode: productCode,
      isViewQuote: isViewQuote,
      parentPolicyId: parentPolicyId,
      selectedPolicyIdForView: selectedPolicyIdForView,
      sourcePolicyId: sourcePolicyId,
      isViewSubQuote: isViewSubQuote,
      activePolicyId: activePolicyId,
      acPaymentId: acPaymentId,
      bankId: bankId,
      accontType: accontType,
      brokerRemittanceId: brokerRemittanceId,
      agentId: agentId,
      exactdate: exactdate,
      backBtnText: backBtnText,
      backBtnPath: backBtnPath
    };
  }

  public GetPolicyDiary(policyinitiatorId: any): Observable<any> {
    return this.dataService.get(this.addOnInsuranceBasePath + "Diary/GetPolicyDiary/" + policyinitiatorId);
  }

  public decryptAndParseQueryStringForClaim(enceyptedData: string) {
    if (enceyptedData === undefined || enceyptedData === null) {
      return;
    }
    const decryptparams = this.decryptData(enceyptedData);
    let policyId;
    let policyNumber: any;
    let productCode: any;
    let policyInsurer: any;
    let policyStartDate: Date;
    let policyEndDate: Date;
    let claimId;
    let productId;
    let redirectPolicyId;
    let isQuote:boolean;

    if (decryptparams.policyId !== undefined && decryptparams.policyId !== null) {
      policyId = parseInt(decryptparams.policyId);
    }
    if (decryptparams.policyNumber !== undefined && decryptparams.policyNumber !== null) {
      policyNumber = decryptparams.policyNumber;
    }
    if (decryptparams.productCode !== undefined && decryptparams.productCode !== null) {
      productCode = decryptparams.productCode;
    }
    if (decryptparams.policyInsurer !== undefined && decryptparams.policyInsurer !== null) {
      policyInsurer = decryptparams.policyInsurer;
    }
    if (decryptparams.policyStartDate !== undefined && decryptparams.policyStartDate !== null) {
      policyStartDate = new Date(decryptparams.policyStartDate);
    }
    if (decryptparams.policyEndDate !== undefined && decryptparams.policyEndDate !== null) {
      policyEndDate = new Date(decryptparams.policyEndDate);
    }
    if (decryptparams.claimId !== undefined && decryptparams.claimId !== null) {
      claimId = parseInt(decryptparams.claimId);
    }
    if (decryptparams.productId !== undefined && decryptparams.productId !== null) {
      productId = parseInt(decryptparams.productId);
    }
    if (decryptparams.redirectPolicyId !== undefined && decryptparams.redirectPolicyId !== null) {
      redirectPolicyId = parseInt(decryptparams.redirectPolicyId);
    }
    if (decryptparams.isQuote !== undefined && decryptparams.isQuote !== null) {
      isQuote = Boolean(decryptparams.isQuote);
    }

    return {
      policyId: policyId,
      policyNumber: policyNumber,
      productCode: productCode,
      policyInsurer: policyInsurer,
      policyStartDate: policyStartDate,
      policyEndDate: policyEndDate,
      claimId: claimId,
      productId: productId,
      redirectPolicyId: redirectPolicyId,
      isQuote:isQuote
    };
  }

  public decryptAndParsePolicyStatus(enceyptedData: string) {
    if (enceyptedData === undefined || enceyptedData === null) {
      return;
    }
    const decryptparams = this.decryptData(enceyptedData);
    let policystatusId;
    if (decryptparams.policystatusId !== undefined && decryptparams.policystatusId !== null) {
      policystatusId = decryptparams.policystatusId;
    }
    return {
      policystatusId: policystatusId,
    };
  }
  public decryptAndParseExportEmail(enceyptedData: string) {
    if (enceyptedData === undefined || enceyptedData === null) {
      return;
    }
    const decryptparams = this.decryptDataExportEmail(enceyptedData);
    let bKcode;
    let id;
    let secID;
    if (decryptparams.bKcode !== undefined && decryptparams.bKcode !== null) {
      bKcode = decryptparams.bKcode;
    }
    if (decryptparams.id !== undefined && decryptparams.id !== null) {
      id = decryptparams.id;
    }
    if (decryptparams.secID !== undefined && decryptparams.secID !== null) {
      secID = decryptparams.secID;
    }
    return {
      bKcode: bKcode,
      id: id,
      secID: secID,
    };
  }

  public decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(
        data,
        CryptoJS.enc.Utf8.parse(this.encryptSecretKey),
        {
          keySize: 128 / 8,
          iv: CryptoJS.enc.Utf8.parse(this.encryptSecretKey),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  public decryptDataExportEmail(data) {
    try {
      const cryptoInfo = CryptoJS.AES.encrypt(JSON.stringify({ data }), 'secret').toString();
      const info2 = CryptoJS.AES.decrypt(cryptoInfo, 'secret').toString(CryptoJS.enc.Utf8);
      const info3 = JSON.parse(info2);
      data: info3.data
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (!control.valid) {
        console.log(field + " Is Invalid");
      }
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const validVal =
      (control.value || "").trim().length == (control.value || "").length;
    if (validVal) {
      return null;
    }
    return { whitespace: true };
  }
}
