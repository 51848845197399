import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NoteType, PolicyStatus } from '@shared/utility/enum';
import { ToasterService } from '@shared/utility/toaster.service';
import { data } from 'jquery';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
@Component({
  selector: 'app-location-shof-ra',
  templateUrl: './location-shof-ra.component.html',
  styleUrl: './location-shof-ra.component.css'
})
export class LocationShofRAComponent  implements OnInit, OnDestroy {
  @Input() locationData: any;
  @Input() item: any;
  town='';
  constructor(
    private activeModal: NgbActiveModal, private fb: FormBuilder,
    private insuranceService: InsuranceService, private toasterService: ToasterService
  ) { }
  ngOnInit() {
    console.log(this.item);
    this.town = this.item?.address.split(" ")[0];
  }
  ngOnDestroy(): void {
  }
  selectRow(data:any){
    this.activeModal.close(data);
  }

  closeDialog() {
    this.activeModal.close(false);
  }
}
