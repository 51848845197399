import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
/** Angular core dependencies */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DisableControlDirective } from './components/directives/disableControl';
import { NumberDirective } from './components/directives/numbers-only.directive';
import { AlphabetDirective } from './components/directives/alphabet-only.directive';
import { SafePipe } from './pipes/safe.pipe';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { PermissionDirective } from '@shared/components/directives/permissionDirective';
import { ConfirmationDialogService } from './components/confirmation-dialog/confirmation-dialog.service';
import { ClickOutsideModule } from 'ng-click-outside';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { NgxPaginationModule } from 'ngx-pagination';
import { NameOnlyDirective } from './components/directives/name-only.directive';
import { MiddleclickDirective } from './components/directives/middleclick-directive';
import { TitleCaseDirective } from './components/directives/titlle-case.directive';
import { TwodecimalDirective } from './components/directives/twodecimal.directive';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ActivityLogPaged } from '@app/services/activity-log-paged.service';
import { AddressVerificationModule } from './controls/address-verification/address-verification.module';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { OpenOnFocusDirective } from './components/directives/open-on-focus-dd.directive ';
import { OpenOnFocusMultiColumnComboBoxDirective } from './components/directives/open-on-focus-multiselect.directive';
import { SpaceInputRestrictionDirective } from './components/directives/space-input-restriction.directive';
import { TooltipsModule } from "@progress/kendo-angular-tooltip";
import { TooltipModule } from 'ng2-tooltip-directive';
import { NegativeTwodecimalDirective } from './components/directives/negative-twodecimal.directive';

@NgModule({
  declarations: [
    DisableControlDirective ,
    NumberDirective,
    AlphabetDirective,
    SafePipe,
    PermissionDirective,
    NameOnlyDirective,
    TitleCaseDirective,
    TwodecimalDirective,
    MiddleclickDirective,
    ShortNumberPipe,
    OpenOnFocusDirective,
    OpenOnFocusMultiColumnComboBoxDirective,
    SpaceInputRestrictionDirective,
    NegativeTwodecimalDirective
  ],
  providers: [ConfirmationDialogService,ActivityLogPaged],
  imports: [InputsModule,
    DropDownsModule,
    DateInputsModule,
    LayoutModule,
    ProgressBarModule,
    ClickOutsideModule,
    TreeViewModule,
    NgxPaginationModule,
    GridModule,
    PDFModule,
    ExcelModule,
    CommonModule,
    NgxIntlTelInputModule,
    AddressVerificationModule,
    NgxIntlTelInputModule,
    TooltipsModule,
    TooltipModule,
  ],
  exports: [ InputsModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    DisableControlDirective,
    DropDownsModule,
    DateInputsModule,
    LayoutModule,
    NumberDirective,
    AlphabetDirective,
    SafePipe,
    ProgressBarModule,
    PermissionDirective,
    ClickOutsideModule,
    TreeViewModule,
    NgxPaginationModule,
    GridModule,
    PDFModule,
    ExcelModule,
    CommonModule,
    NameOnlyDirective,
    TitleCaseDirective,
    TwodecimalDirective,
    MiddleclickDirective,
    NgxIntlTelInputModule,
    AddressVerificationModule,
    ShortNumberPipe,
    OpenOnFocusDirective,
    OpenOnFocusMultiColumnComboBoxDirective,
    SpaceInputRestrictionDirective,
    NgxIntlTelInputModule,
    TooltipsModule,
    TooltipModule,
    NegativeTwodecimalDirective,
  ]
})
export class SharedModule {
  static forRoot(type: string): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [{ provide: "", useValue: type }]
    };
  }
}
