import { Directive, ElementRef, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appSpaceInputRestriction]',
})
export class SpaceInputRestrictionDirective {
  regexStr = '^[a-zA-Z0-9_ ]*$';
  @Input() isAlphaNumeric: boolean;

  constructor(private el: ElementRef , @Optional() private ngControl: NgControl) {
    console.log(el);
   }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) { 
   if ( this.el.nativeElement.selectionStart === 0 && event.key === ' ' ) {
     event.preventDefault();
   }

    // if (!RegExp(this.regexStr).test(event.key)) {
    //   event.preventDefault();
    // }
   // this.el.nativeElement.value.trim();
   // this.ngControl.control.setValue(this.el.nativeElement.value.trim());
   // event.preventDefault();
  }
  @HostListener('blur') onBlur() {
    this.ngControl.control.setValue(this.el.nativeElement.value.trim());
  }
  // validateFields(event) {
  //   setTimeout(() => {

  //     this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '').replace(/\s/g, '');
  //     event.preventDefault();

  //   }, 100)
  // }
}
