import { Injectable } from "@angular/core";

import { Observable, BehaviorSubject } from "rxjs";
import { tap, map } from "rxjs/operators";

import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";
// import { DataService } from "@progress/kendo-angular-dropdowns";
import { AuthService } from "@app/auth/auth.service";
import { DataService } from '@shared/async-services/data.service';


@Injectable()
export class ActivityLogPaged extends BehaviorSubject<any[]> {
    public state: State = {
        skip: 0,
        take: 10,
    };
    public endpoint = `Activity/Activity/GetActivityByFilter`;
    // private BASE_URL = "agent/list";
    constructor(
        private dataService: DataService,
        private authService: AuthService
    ) {
        super([]);
    }

    public read(
        key: string = "",
        module: string = "",
        subModule: string = "",
        action: string = "",
        keyNumber: string ="",
    ) {
        this.fetch( key, module, subModule,action,keyNumber)
            .pipe(tap((data) => { }))
            .subscribe((data) => {
                super.next(data);
            });
    }

    public fetch(
        
        key: string = "",
        module: string = "",
        subModule: string = "",
        action: string = "",
        keyNumber:string ="",
    ): Observable<any> {
        switch (action) {
            case "": {
                let queryStr = `${toDataSourceRequestString(this.state)}`;
                const params = queryStr.split("&");
                const objSearch = Object.assign({});

                objSearch.pageIndex = +params[0].split("=")[1] - 1;
                objSearch.pageSize = params[1].split("=")[1];
                objSearch.key =Number(key);
                objSearch.module = module;
                objSearch.subModule = subModule;
                objSearch.keyNumber = keyNumber;

                return this.dataService.post(`${this.endpoint}`, objSearch).pipe(
                    // Process the response.
                    map((data: any): any => {
                        return {
                            // data: data.lstActivityViewModals,
                            // total: parseInt(data.totalItems),
                            data: data.items,
                            total: parseInt(data.totalCount),
                        };
                    })
                );
            }

        }
    }

    queryAll(
        
        key: string = "",
        module: string = "",
        subModule: string = "",
        action: string = "",
        keynumber: string ="",
    ): Observable<GridDataResult[]> {
        this.state.skip = 0;
        this.state.take = 99999;
        return this.fetch(key, module, subModule,action,keynumber);
    }
}
