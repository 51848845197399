import { Injectable } from '@angular/core';
import 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from '@shared/async-services/data.service';

@Injectable()
export class AddressVerificationService {
  addressbasePath= 'address/';
  constructor(
    private _dataService: DataService,
  ) {}

  getPlaceDetails(route: string, text: string, hmo : string, isRiskAddress : boolean=false): Observable<any> {
    return this._dataService
      .get(this.addressbasePath + route + '?text=' + text);
    
  }

  getDetails(route: string, addressId: number): Observable<any> {
    return this._dataService
      .get(this.addressbasePath + route + '/' + addressId);
    
  }

  

   

  getCountries(): Observable<any> {
    return this._dataService
      .get('insurance/countries');
  }
  getAddressFromAddressId(addressId: number): Observable<any>{
    return this._dataService
      .get(`Property/Address/GetAddressDetail/${addressId}`);
  }
}
