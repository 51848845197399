import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

@Directive({
  selector: 'kendo-combobox[openOnFocus]'
})
export class OpenOnFocusDirective {

  @Input() openOnFocus: ComboBoxComponent;
  
  @HostListener('focus') onFocus() {
    
   // this.openOnFocus.clearValue();
    this.openOnFocus.toggle(true);
    //this.openOnFocus.handleEscape();
      
   // this.openOnFocus.blur()
   //this.openOnFocus.reset()
  }

  @HostListener('blur') onBlur() {
    this.openOnFocus.toggle(false);
  }

  // @HostListener('closed') onclosed() {
    
  //   this.openOnFocus.toggle(false);
  // }
  // @HostListener('valueChange') onValueChange() {
  //   // this.openOnFocus.toggle(true);
  //   // $("#comboBox").data("kendoComboBox").text('');
  //   //this.openOnFocus.reset()
  //   this.openOnFocus.toggle(true);
  //   //this.openOnFocus.blur()
  // }
  // @HostListener('selectionChange') onselectionChange () {
  //   console.error('reset.');
  //   this.openOnFocus.toggle(true);
  //   //this.openOnFocus.reset()
  //   console.error('reset.');
  //   // this.openOnFocus.focus()
  //   // this.openOnFocus.focus()
  //   // this.openOnFocus.focus()
  // }
  // ngAfterViewInit() {
  //   //alert('ngAfterViewInit call')
  //   this.openOnFocus.focus();
  //   this.openOnFocus.blur()
  // }
}