import {
  Component,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input
} from "@angular/core";
import { fromEvent } from "rxjs";
import { map, filter, debounceTime, distinctUntilChanged, tap, switchMap } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListTable } from "@shared/models";
import { AddressVerificationService } from "./address-verification.service";
import { getBoolean } from "@shared/utility";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "location-control",
  templateUrl: "./address-verification.component.html",
  styleUrls: ["./address-verification.component.scss"]
})
export class AddressVerificationComponent implements OnInit, OnChanges {
  @Input() group: FormGroup;
  @Input()
  isRiskAddress = false;
  @Input() isAllowEnglandAndWalesOnly = false;
  @Input()
  hmo = '';

  @Input()
  isrequired = false;

  frmaddresscontrol: FormGroup;

  public addressresults: [];
  public isAddress;
  displayResult = "none";
  public addressarea;
  pcaexpandable: false;
  divWithExpandableClass = "pcaitem pcaexpandable";
  divWithOutExpandableClass = "pcaitem";
  divWithSelectedClass = "pcaitem pcaexpandable pcaselected";
  isNonUk = false;
  apiResponse: any;
  isSearching: boolean;
  showAddr: boolean = true;
  @ViewChild("searchAddressInput", { static: true }) searchAddressInput: ElementRef;
  public lstCountry: Array<ListTable>;
  public dataCountry: Array<ListTable>;
  public noAddressFound: boolean = false;
  addressData;
  public realaddress = {
    addressJson: "",
    addr1: "",
    addr2: "",
    country: "",
    postcode: "",
    postcodePart1: "",
    postcodePart2: "",
    latitude: null,
    longitude: null,
    town: "",
    addressId: '0',
    propertyId: '0',
    displayAddress: '',
    address: ''
  };

  isNonUkModel;
  countryId;
  country;
  isdisabled = true;
  @Output() talk: EventEmitter<string> = new EventEmitter<string>();
  @Input() isControlDisabled = false;
  @Input() idbind = "txtAddress";
  @Input()
  isAlignLeft = 0;

  @Input()
  displayText = true;

  get selectedAddress(): any {
    return this.realaddress;
  }

  @Input() set selectedAddress(value: any) {
    if (this.realaddress != value) {
      this.realaddress = value;
      this.isAddress = true;
    }
  }

  constructor(
    private _autoCompleteSearchService: AddressVerificationService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {

    this.frmaddresscontrol = this.fb.group({
      address: [''],
    });
  }

  ngOnInit(): any {
    //  this.fillCountry();
    this.setValidation();
    fromEvent(this.searchAddressInput.nativeElement, "keyup").pipe(
      map((event: any) => {
        this.noAddressFound = false;
        if (event.target.value === "" && event.target.value.length === 0) {
          this.displayResult = "none";
          this.talk.emit(null);
          this.addressresults = [];
        }
        return event.target.value;
      })
      , filter((res: any) => res.trim().length > 3)
      , debounceTime(1200)
      , distinctUntilChanged()
      , tap(_ => (this.isSearching = true))
      , switchMap((searchValue: any) => {
        if (this.addressarea !== undefined && this.addressarea.length === 0) {
          this.displayResult = "none";
          this.addressresults = [];
          return;
        }
        //searchValue = searchValue.trim();
        if (searchValue !== null && searchValue.length >= 3 && searchValue.trim().length > 0) {
          this.addressresults = [];
          return this._autoCompleteSearchService
            .getPlaceDetails('address/searchlocation', searchValue, '', this.isRiskAddress);
        }
      })
      , tap((_) => (this.isSearching = false)))
      .subscribe((data: any): void => {
        this.addressresults = data?.locations?.irelanD_AC_EIRCODE;;
        if (this.addressresults !== null) {
          this.apiResponse = data?.locations?.irelanD_AC_EIRCODE;;
          this.displayResult = "Block";

          if (this.addressresults.length > 0)
            this.noAddressFound = false;
          else
            this.noAddressFound = true;
        }
        else
          this.noAddressFound = true;
      });

    // setTimeout(() => {
    //   this.formaddress.nativeElement.reset();
    // }, 2000);
  }

  ngOnChanges(): any { }

  setValidation() {
    if (this.isrequired) {
      this.frmaddresscontrol.controls["address"].setValidators(Validators.required);
    } else {
      this.frmaddresscontrol.controls["address"].clearValidators();
      this.frmaddresscontrol.controls["address"].reset();
    }
    this.frmaddresscontrol.controls["address"].updateValueAndValidity();
  }

  listendivClick(item: any) {

    this.displayResult = "none";
    this.getDetailAddress(item.id);
    this.addressarea = "";
  }



  getDetailAddress(id: number) {
    this.addressresults = [];
    this._autoCompleteSearchService
      .getDetails("address/searchdetail", id)
      .subscribe((data: any): void => {
        this.addressresults = [];
        if (this.addressresults !== null) {
          this.displayResult = "none";
          // this.isAddress = true;

          this.addressarea = data?.response?.locations?.location?.address + ', ' + data?.response?.locations?.location?.postcode;
          //this.addressarea='';
          this.talkBack(data?.response?.locations?.location);
        }
      });

  }

  talkBack(realAddress: any) {
    if (this.group) {
      const ClaimData: any = [];
      ClaimData.push({
        claimAddress: realAddress,
        claim: this.group
      })
      this.talk.emit(ClaimData);
    } else {
      var add= `${realAddress.address}, ${realAddress.postcode}`;
      //""16 CALTRAGH, SLIGO, ""
      realAddress.address =add.trimEnd();      
      this.talk.emit(realAddress);
    }

    // this.town = realAddress.street;
    // this.addressLine1 = realAddress.line1;
    // this.addressLine2 = realAddress.line2;
    // this.postcode = realAddress.postalCode;
    this.isAddress = true;
    this.realaddress = realAddress;
    // this.countryId = this.lstCountry.filter(
    //   a => a.text === 'United Kingdom'
    // )[0].value;
  }

  NonUk(): void {
    this.isNonUk = !this.isNonUk;
    if (this.isNonUkModel) {
      this.isdisabled = false;
      this.addressarea = "";
    } else {
      this.isdisabled = true;
    }
    this.clearControl();
  }

  fillCountry(): void {
    this._autoCompleteSearchService
      .getCountries()
      .subscribe((data: any): void => {
        this.lstCountry = data;
        this.dataCountry = this.lstCountry.slice();
        this.countryId = this.lstCountry.filter(
          a => a.text === "United Kingdom"
        )[0].value;
      });
  }

  handleFiltercountry(value: any): void {
    this.dataCountry = this.lstCountry.filter(
      s => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  onCountryChange(value): void {
    this.country = this.lstCountry.filter(a => a.value === value)[0].text;

  }

  clearControl() {
    // this.addressLine1 = '';
    // this.addressLine2 = '';
    // this.town = '';
    // this.postcode = '';
    this.countryId = "";

    this.realaddress.addressJson = "";
    this.realaddress.addr1 = "";
    this.realaddress.addr2 = "";
    this.realaddress.town = "";
    this.realaddress.postcode = "";
    this.realaddress.postcodePart1 = "";
    this.realaddress.postcodePart2 = "";
    this.realaddress.country = "";
    this.realaddress.latitude = null;
    this.realaddress.longitude = null;
    this.realaddress.address = '';
    this.displayResult = "none";
    this.addressarea = null;
  }

  setAddressExplicitylity(addr: any) {
    this.realaddress = addr;
  }

  cantFind(isEdit = false) {

  }
}
