import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AddressVerificationService } from './address-verification.service';
import { AddressVerificationComponent } from './address-verification.component';
import { AddressVerificationDataComponent } from '../address-verification-data/address-verification-data.component';
@NgModule({
  declarations: [
    AddressVerificationComponent,
    AddressVerificationDataComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownsModule
  ],
 
  providers : [ AddressVerificationService],
  exports :[AddressVerificationComponent,AddressVerificationDataComponent]
})
export class AddressVerificationModule {

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: AddressVerificationModule
    };
  }

}