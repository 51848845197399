import {
  Component,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input
} from "@angular/core";
import { fromEvent } from "rxjs";
import { map, filter, debounceTime, distinctUntilChanged, tap, switchMap } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListTable } from "@shared/models";
import { getBoolean } from "@shared/utility";
import { AddressVerificationService } from "../address-verification/address-verification.service";
import { LocationShofRAComponent } from "../location-shof-ra/location-shof-ra.component";

@Component({
  selector: 'app-address-verification-data',
  templateUrl: './address-verification-data.component.html',
  styleUrl: './address-verification-data.component.css'
})


export class AddressVerificationDataComponent implements OnInit, OnChanges {
  @Input() group: FormGroup;
  @Input()
  isRiskAddress = false;
  @Input() isAllowEnglandAndWalesOnly = false;
  @Input()
  hmo = '';

  @Input()
  isrequired = false;

  frmaddresscontrol: FormGroup;

  public addressresults: [];
  public isAddress;
  displayResult = "none";
  public addressarea;
  public addressSubarea;
  public isStreet = false;
  public isCounty = false;
  public countyName;
  pcaexpandable: false;
  divWithExpandableClass = "pcaitem pcaexpandable";
  divWithOutExpandableClass = "pcaitem";
  divWithSelectedClass = "pcaitem pcaexpandable pcaselected";
  isNonUk = false;
  apiResponse: any;
  isSearching: boolean;
  showAddr: boolean = true;
  @ViewChild("searchAddressInput", { static: true }) searchAddressInput: ElementRef;
  public lstCountry: Array<ListTable>;
  public dataCountry: Array<ListTable>;
  public noAddressFound: boolean = false;
  addressData;
  public realaddress = {
    addressJson: "",
    addr1: "",
    addr2: "",
    country: "",
    postcode: "",
    postcodePart1: "",
    postcodePart2: "",
    latitude: null,
    longitude: null,
    town: "",
    addressId: '0',
    propertyId: '0',
    displayAddress: '',
    address: '',
    streetName: ''
  };

  isNonUkModel;
  countryId;
  country;
  isdisabled = true;
  @Output() talk: EventEmitter<string> = new EventEmitter<string>();
  @Input() isControlDisabled = false;
  @Input() idbind = "txtAddress";
  @Input()
  isAlignLeft = 0;

  @Input()
  displayText = true;

  get selectedAddress(): any {
    return this.realaddress;
  }

  @Input() set selectedAddress(value: any) {
    if (this.realaddress != value) {
      this.realaddress = value;
      this.isAddress = true;
    }
  }

  constructor(
    private _autoCompleteSearchService: AddressVerificationService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {

    this.frmaddresscontrol = this.fb.group({
      address: [''],
    });
  }

  ngOnInit(): any {
    //  this.fillCountry();
    this.setValidation();
    fromEvent(this.searchAddressInput.nativeElement, "keyup").pipe(
      map((event: any) => {
        this.noAddressFound = false;
        if (event.target.value === "" && event.target.value.length === 0) {
          this.displayResult = "none";
          this.addressSubarea = "";
          this.isStreet = false;
          this.isCounty = false;
          this.countyName = "";
          this.talk.emit(null);
          this.addressresults = [];
          this.addressarea = '';
          this.ngOnInit()
        }
        return event.target.value;
      })
      , filter((res: any) => res.trim().length > 3)
      , debounceTime(1200)
      , distinctUntilChanged()
      , tap(_ => (this.isSearching = true))
      , switchMap((searchValue: any) => {
        if (this.addressarea !== undefined && this.addressarea.length === 0) {
          this.displayResult = "none";
          this.addressresults = [];
          return;
        }
        //searchValue = searchValue.trim();
        if (searchValue !== null && searchValue.length >= 3 && searchValue.trim().length > 0) {
          this.addressresults = [];
          return this._autoCompleteSearchService
            .getPlaceDetails('address/AddressSearch', searchValue, '', this.isRiskAddress);
        }
      })
      , tap((_) => (this.isSearching = false)))
      .subscribe((data: any): void => {
        this.addressresults = data;
        if (this.addressresults !== null) {
          this.apiResponse = data;
          this.displayResult = "Block";

          if (this.addressresults.length > 0) {
            this.noAddressFound = false;
          }
          else {
            this.isCounty = false;
            this.isStreet = false;
            this.addressSubarea = "";
            this.countyName = "";
            this.noAddressFound = true;
          }
        }
        else
          this.noAddressFound = true;
      });
  }

  ngOnChanges(): any { }

  setValidation() {
    if (this.isrequired) {
      this.frmaddresscontrol.controls["address"].setValidators(Validators.required);
    } else {
      this.frmaddresscontrol.controls["address"].clearValidators();
      this.frmaddresscontrol.controls["address"].reset();
    }
    this.frmaddresscontrol.controls["address"].updateValueAndValidity();
  }

  listendivClick(item: any) {

    this.displayResult = "none";
    this.getDetailAddress(item);
    this.addressarea = "";
    this.addressSubarea = "";
    this.countyName = "";
    this.isStreet = false;
    this.isCounty = false;
  }



  getDetailAddress(item: any) {
    this.addressresults = [];
    this._autoCompleteSearchService
      .getDetails("address/AddressSearchDetail", item.townID)
      .subscribe((data: any): void => {
        if (item.isSubArea == true) {
          data.push({
            raid: 0,
            raname: 'None of the above',
            subsidenceZone: -1,
            townId: "t99999"
          })
          this.locationStreet(data, item);
        }
        this.addressresults = [];
        if (this.addressresults !== null) {
          this.displayResult = "none";
          this.addressarea = item?.townName;// + ', ' + item?.countyCode;
          this.countyName = item?.countyName;
          this.isCounty = true;
          // this.addressarea = data?.response?.locations?.location?.address + ', ' + data?.response?.locations?.location?.postcode;
          this.talkBack(item);
        }
      });

  }
  locationStreet(data: any, item: any) {
    const modalref = this.modalService.open(LocationShofRAComponent, {
      backdrop: "static",
      backdropClass: "modal-on-modal",
      windowClass: "modal-on-modal-dialog",
      centered: true,
      size: "700",
    });
    modalref.componentInstance.locationData = data;
    modalref.componentInstance.item = item;
    modalref.result.then((data: any) => {
      if (data != false) {
        this.addressSubarea = data.raname;
        this.isStreet = true;
        item.streetName = data.raname;
        this.talkBack(item);
      }
    });
  }
  talkBack(realAddress: any) {
    
    if (this.group) {
      const ClaimData: any = [];
      ClaimData.push({
        claimAddress: realAddress,
        claim: this.group
      })
      this.talk.emit(ClaimData);
    } else {
      // realAddress.address = `${realAddress.townName}, ${realAddress.countyCode}`;
      realAddress.address = `${realAddress.townName}`;
      this.talk.emit(realAddress);
    }

    this.isAddress = true;
    this.realaddress = realAddress;
  }

  NonUk(): void {
    this.isNonUk = !this.isNonUk;
    if (this.isNonUkModel) {
      this.isdisabled = false;
      this.isCounty = false;
      this.addressarea = "";
      this.countyName = "";
    } else {
      this.isdisabled = true;
    }
    this.clearControl();
  }

  fillCountry(): void {
    this._autoCompleteSearchService
      .getCountries()
      .subscribe((data: any): void => {
        this.lstCountry = data;
        this.dataCountry = this.lstCountry.slice();
        this.countryId = this.lstCountry.filter(
          a => a.text === "United Kingdom"
        )[0].value;
      });
  }

  handleFiltercountry(value: any): void {
    this.dataCountry = this.lstCountry.filter(
      s => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  onCountryChange(value): void {
    this.country = this.lstCountry.filter(a => a.value === value)[0].text;

  }

  clearControl() {
    // this.addressLine1 = '';
    // this.addressLine2 = '';
    // this.town = '';
    // this.postcode = '';
    this.countryId = "";

    this.realaddress.addressJson = "";
    this.realaddress.addr1 = "";
    this.realaddress.addr2 = "";
    this.realaddress.town = "";
    this.realaddress.postcode = "";
    this.realaddress.postcodePart1 = "";
    this.realaddress.postcodePart2 = "";
    this.realaddress.country = "";
    this.realaddress.latitude = null;
    this.realaddress.longitude = null;
    this.realaddress.address = '';
    this.realaddress.streetName = '';
    this.displayResult = "none";
    this.addressarea = null;
    this.countyName = "";
  }

  setAddressExplicitylity(addr: any) {
    this.realaddress = addr;
  }

  cantFind(isEdit = false) {

  }
}

