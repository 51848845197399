import { Directive, HostListener, Input } from '@angular/core';
import { MultiColumnComboBoxComponent } from '@progress/kendo-angular-dropdowns';

@Directive({
  selector: 'kendo-multicolumncombobox[openOnFocus]'
})
export class OpenOnFocusMultiColumnComboBoxDirective {

  @Input() openOnFocus: MultiColumnComboBoxComponent;

  @HostListener('focus') onFocus() {
    this.openOnFocus.toggle(true);
  }

  @HostListener('blur') onBlur() {
    this.openOnFocus.toggle(false);
  }
}