import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  AfterContentInit
} from "@angular/core";
@Directive({
  selector: '[permission]'
})
export class PermissionDirective implements OnInit {
 
  private _claimName: string;
  condition: boolean;
 
  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {
  }
 
  ngOnInit(): void {
    this.hideshow();
  }
 
  hideshow() {
    this.viewContainer.clear();
    if (this.condition) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
 
    }
  }
 
  @Input()
  set permission(claimName: string) {
    this._claimName = claimName;
    this.setCondition();
  }
 
  setCondition() {
    const auth = JSON.parse(window.sessionStorage.getItem('authentication'));
    if(auth===null) {
      return;
    }
 
    // const ismanager = auth.userSystem.find(a => a.systemId === 32768);
    // if (ismanager) {
    //   this.condition = true;
    //   this.checkViewStatus();
    //   return;
    // }
    this.condition = false;
    if (auth && this._claimName) {
      const isExists = auth.claims.find(a => a.toString().toLowerCase() === this._claimName.toLowerCase());
      this.condition = isExists && isExists !== undefined && isExists !== null;
      this.checkViewStatus();
    }
  }
  private _except: boolean;
  @Input()
  set permissionShow(value: boolean) {
    this._except = value;
    this.setCondition();
    this.checkViewStatus();
  }
 
  private checkViewStatus() {
    if (this._except !== undefined && this.condition) {
      this.condition = this._except;
    }
    this.hideshow();
  }
 
}