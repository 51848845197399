<form novalidate [formGroup]="frmaddresscontrol">
  <div>
    <label class="w-100" *ngIf="isStreet">Street Level: {{addressSubarea}}</label>
    <label>Town/City:</label>
    <input [id]="idbind" class="form-control form-control-mobile" type="text" placeholder="Address"
      autocomplete="off" [(ngModel)]="addressarea" [attr.disabled]="isControlDisabled" #searchAddressInput
      formControlName="address" />
    <label class="mt-1" *ngIf="isCounty">County: {{countyName}}</label>
    <div class="validation-msg-label" *ngIf="isrequired && 
                              (addressarea==='' || 
                              addressarea===null || 
                              addressarea===undefined) &&
                              frmaddresscontrol?.controls.address.touched  
                              && frmaddresscontrol?.controls.address.errors?.required">
      Required
    </div>

    <div class="pca" *ngIf="addressresults?.length > 0 || noAddressFound">
      <div class="pcaautocomplete pcatext" [ngStyle]="{ display: displayResult }"
        style="top: 436px; left: 966.5px; min-width: 481px">
        <div class="pca pcalist" style="height: 216px; display: none"></div>
        <div [ngClass]="
              item.type !== 'Address'
                ? divWithExpandableClass
                : divWithOutExpandableClass" *ngFor="let item of addressresults; let i = index" title="{{ item.displayTown }}"
          (click)="listendivClick(item)">
          <b class="font-weight-semibold">{{ item.displayTown }}</b>
        </div>
      </div>
    </div>
  </div>
</form>