<form novalidate [formGroup]="frmaddresscontrol">
    <div>
      <input [id]="idbind" class="form-control form-control-mobile" type="text"
        placeholder="Eircode or Address" autocomplete="off" [(ngModel)]="addressarea"
        [attr.disabled]="isControlDisabled" maxlength="200"
         #searchAddressInput formControlName="address" />
         
      <div class="validation-msg-label" *ngIf="isrequired && 
                              (addressarea==='' || 
                              addressarea===null || 
                              addressarea===undefined) &&
                              frmaddresscontrol?.controls.address.touched  
                              && frmaddresscontrol?.controls.address.errors?.required">
        Required
      </div>
      <!-- <div class="validation-msg-label" *ngIf="isrequired && 
                              (realaddress.addr1==='' || 
                              realaddress.addr1===null || 
                              realaddress.addr1===undefined) &&
                              frmaddresscontrol?.controls.address.touched  
                              && frmaddresscontrol?.controls.address.errors?.required">
        Required
      
      </div> -->
      <div class="pca" *ngIf="addressresults?.length > 0 || noAddressFound">
        <div class="pcaautocomplete pcatext" [ngStyle]="{ display: displayResult }"
          style="top: 436px; left: 966.5px; min-width: 481px">
          <div class="pca pcalist" style="height: 216px; display: none"></div>
          <div [ngClass]="
              item.type !== 'Address'
                ? divWithExpandableClass
                : divWithOutExpandableClass
  
            "
            *ngFor="let item of addressresults; let i = index"
            title="{{ item.text }}"
            (click)="listendivClick(item)"
          >
            <b class="font-weight-semibold"
              >{{ item.text }}</b
            >
            <!-- <span class="pcadescription"
              >{{ item.text }} - {{ item.description }}</span
            > -->
          </div>
          <div class="nodata-found" *ngIf="addressresults?.length==0">
                No matches found
          </div>
        </div>
        
      </div>
  
     
    </div>
    
  </form>
  