import { Directive, ElementRef, HostListener } from "@angular/core";


@Directive({
    selector: 'input[appTitleCase]'
  })
  export class TitleCaseDirective {
  
    constructor(private el: ElementRef) {
    }
    
    
    // @HostListener('input',['$blur']) onBlur(evt) {
    //   if (this.el.nativeElement.value) {
    //     const arr: string[] = this.el.nativeElement.value.split('');
    //     arr[0] = arr[0].toUpperCase();
    //     this.el.nativeElement.value = arr.join('');
    //  }
    // }

    @HostListener('input',['$event']) onBlur(e) {
      if (this.el.nativeElement.value) {
        if(e.target.selectionStart == 1){
        var start = e.target.selectionStart;
        var end = e.target.selectionEnd;
        
        //e.target.value = e.target.value.substring(0, start).toUpperCase() + e.target.value.substring(end);
        e.target.value = e.target.value.substring(0, start).toUpperCase() + e.target.value.slice(1);

        var startString = e.target.value.substring(0, start).toUpperCase();
        var endString = e.target.value.substring(1).toLowerCase();
        this.el.nativeElement.value = startString + endString;
        
        e.target.setSelectionRange(start, start);
        this.el.nativeElement.setSelectionRange(start,start);
        e.preventDefault();
        }
      } 
    }

    // @HostListener('focusout', ['$event']) onFocusout(event) {
    //   
    //   if (this.el.nativeElement.value) {
    //     const arr: string[] = this.el.nativeElement.value.split('');
    //     arr[0] = arr[0].toUpperCase();
    //     this.el.nativeElement.value = arr.join('');
    //  }
    // }
    
  }