import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[nameOnly]'
})
export class NameOnlyDirective {

  constructor(private _el: ElementRef) { }

  // @HostListener('input', ['$event']) onInputChange(event) {
  //   const initalValue = this._el.nativeElement.value;
  //   this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z- ']*/g, '');
  //   if ( initalValue !== this._el.nativeElement.value) {
  //     event.stopPropagation();
  //   }
  // }

  key;
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    
    this.key = event.keyCode;
    
    if (this.key === 32 || this.key === 189 || this.key === 222 || this.key === 37 || this.key === 38 || this.key === 39 || this.key === 40 || this.key === 192 ) {
      return;
    }
    if ((this.key >= 15 && this.key <= 64) || (this.key >= 123) || (this.key >= 96 && this.key <= 105)) {
      event.preventDefault();
    }
  }

}