<div class="modal-header">
    <div class="row justify-content-between align-items-center">
        <div class="col-md-auto">
            <h4 class="modal-title">Location
            </h4>
        </div>
        <div class="col-md-auto">
            <span class="material-symbols-outlined cursor-pointer" (click)="closeDialog()">
                close
            </span>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="row">
        <p>The location of the business premises you have entered is {{town}} in {{item?.countyName}}.</p>
<p>Please select the sub area of the premises below.</p>
    </div>
  
    <div class="row">
        <div class="col-md-12 position-relative">
            <table class="table table-bordered">
                <tr>
                  
                    <th>Sub Area</th>
                    
                </tr>
                <tr  *ngFor="let item of this.locationData; let i = index">
                  
                    <td (click)="selectRow(item,i)">{{item.raname}}</td>
                    
                </tr>
            </table>
        </div>
    </div>
</div>