<div class="modal-header">
  <h4 class="modal-title">{{ title }}
  </h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="form-group col-md-12">
      {{ message }}
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="row w-100 justify-content-end">
    <div class="col-md-auto">
      <button type="button" *ngIf="isShowCancelButton" class="btn btn-sm btn-danger me-2"
        (click)="decline()">{{ btnCancelText }}</button>
      <button type="button" class="btn btn-sm btn-primary" (click)="accept()">{{ btnOkText }}</button>
    </div>
  </div>
</div>